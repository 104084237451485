import axios from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: {},
});

export const get = async ({
  baseURL = null,
  url,
  data = {},
  headers = {},
  accessToken = true,
}) => {
  const response = await apiClient.get(url, {
    ...(baseURL && { baseURL }),
    data,
    headers: {
      ...apiClient.defaults.headers,
      ...headers,
      ...(accessToken
        ? { Authorization: `Bearer ${Cookies.get("access_token")}` }
        : {}),
    },
  });
  return response;
};

export const post = async ({
  baseURL = null,
  url,
  data,
  headers = {},
  options = {},
  accessToken = true,
}) => {
  const response = await apiClient.post(url, data, {
    ...(baseURL && { baseURL }),
    headers: {
      ...apiClient.defaults.headers,
      ...headers,
      ...(accessToken
        ? { Authorization: `Bearer ${Cookies.get("access_token")}` }
        : {}),
    },
    ...options,
  });
  return response;
};
